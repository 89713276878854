.about-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(./../../images/pattern.svg);
}

.about-container {
    margin: 20px 10px;
}

.about-us-content {
    max-width: 70%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about-us-content .title  p {
    font-size: 54px;
    line-height: 65px;
    text-transform: capitalize;
}

.about-msg p {
    line-height: 65px;
    font-size: 30px;
}

.about-working-hours {
    margin-block: 30px;
}

.about-working-hour-title {
    margin-block: 10px;
    font-size: 20px;
    line-height: 40px;
    text-transform: uppercase;
}

.about-working-hours .hours{
    display: flex;
    gap: 30px
}

@media (max-width: 1025px) {
    .about-us-content .title p {
        font-size: 40px;
    }

    .about-us-content .about-msg p {
        font-size: 20px;
        line-height: 35px;
    }
}

@media (max-width: 769px) {
    .about-us-content .title p {
        font-size: 30px;
    }

    .about-us-content .about-msg p {
        font-size: 18px;
        line-height: 30px;
    }

    .about-working-hour-title {
        font-size: 18px
    }
    .about-working-hours .hours {
        flex-wrap: wrap;
    }

    .about-working-hours .hours div {
        min-width: 45%;
    }

    .footer-wrapper .legal {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 450px) {
    .about-us-content {
        max-width: 90%;
    }
    .about-us-content .title p {
        font-size: 30px;
    }

    .about-us-content .about-msg p {
        font-size: 18px;
        line-height: 30px;
    }

    .about-working-hour-title {
        font-size: 18px
    }
    .about-working-hours .hours {
        flex-wrap: wrap;
    }

    .about-working-hours .hours div {
        min-width: 45%;
    }
}