.footer {
    color: #666;
    font-family: 'Poppins', sans-serif;
  }

  .footer-wrapper {
    width: 90%;
    margin: 0 auto;
    border-top: 1px solid rgb(16, 26, 36, 0.1);
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;

  }
 

  .footer .social-icons {
    display: flex;
    gap: 15px
  }
  
  .footer .social-icons a img {
    width: 20px;
    height: 20px;
  }

  .legal {
    display: flex;
    gap: 40px
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }

    .copyright .xtra {
      display: none;
    }

    .footer-wrapper .copyright p {
      display: flex;
    }

    .legal p:last-child {
      display: none;
    }
  }

  @media (max-width: 500px) {
    .footer-wrapper {
        flex-direction: column-reverse;
        width: 100%;
        padding-bottom: 20px;
    }

    .copyright .xtra {
      display: block;
    }

    .footer-wrapper .legal {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
    }

    .footer-group.top .right {
        flex-direction: column;
        padding-block: 20px;
        border-block: 2px solid #ccc;
        margin-block: 20px;
    }

    .footer-group.top .right .menu{
        margin-bottom: 10px;
    }

    .social-icons a {
        margin: 10px 0;
    }

    .legal p:last-child {
      display: block;
    }

    div.footer-group.down .hours {
        display: block;
    }
  }