.menu-slider {
    overflow: hidden;
    width: 100%; /* Adjust to your desired width */
  }
  
  .menu-list {
    display: flex;
    transition: transform 0.3s ease-in-out;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    color: #000;
  }
  
  .menu-item {
    width: 20%; /* Adjust based on visible items */
    flex-shrink: 0;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.3s;
    cursor: pointer;
  }
  
  .menu-item.visible {
    opacity: 1;
  }
  
  .menu-item.hidden {
    display: none;
  }

  .menu-item.active {
    color: var(--hot-pink);
  }

  .slider-container {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .btn-container button {
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
  }
  
  .btn-container button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 1023px) {
    .menu-item {
      padding: 8px;
      font-size: 14px;
      margin: 0 5px;
    }
  
    .slider-btn {
      padding: 8px;
      font-size: 14px;
    }
  }

  @media (max-width:480px) {
    
    .slider-container {
      padding: 0;
    }

    .menu-list {
      justify-content: flex-start;
      padding-block: 10px 5px;
    }
  

    .menu-item {
      flex: 1 1 calc(30% - 20px);
      margin-inline: 10px;
    }
  
    .slider-btn {
      display: none; 
    }
  }
  