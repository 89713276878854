/*.menuSection {
    border: 8px solid rgb(17, 233, 215);
  } */
  .menu-header {
    /* margin: 20px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }

  .menu-header .hr {
    height: 3px;
    min-width: 40%;
    background-color:  #868686;
    ;
  }

  .menu-item-category {
    color: #DA127C;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    line-height: 33px;

  }

  .year {
    font-weight: 300;
    font-style: oblique;
    color: #DA127C;
    line-height: 22.5px;
    padding-top: 7px;
  }

  .menu-content {
    margin-block: 10px 20px;
    display: flex;
    /* border: 2px solid rgb(102, 255, 0); */
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .menu-content-item {
    /* border: 2px solid red; */
    width: 45%;
    display: flex;
    justify-content: space-between;
    margin-block: 10px;
  }

  .menu-item-name {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: black;

  }

  .menu-item-info {
    text-transform: capitalize;
    font-weight: 400;
    color: #868686;
    line-height: 22.5px;
  }

  .menu-item-cost {
    font-size: 20px;
    color: black;
    font-weight: 400;
    line-height: 30px;
  }

  .wine-section {
    margin-block: 50px;
    /* border: 2px solid salmon; */
  }

  .wine-section .menu-content {
    /* border:  2px solid blue; */
    padding-inline: 2%;
  }

  .wine-section .menu-content .left {
    display: flex;
  }

  .wine-section .details {
    padding-left: 5px;
  }

  .wine-section .img img {
    width: 50px;
  }

  /* Responsive Design */
  @media (max-width: 1023px) {
    .menu-item-category {
      text-align: center;
      padding-inline: 15px;
      text-align: center;
    }

    .wine-section .menu-content {
      padding-block: 15px;
    }

    .wine-section .menu-content-item {
      width: 100%;
      border-bottom: 1px dotted;
      padding-block: 20px;
      margin-block: 0;
    }

    .wine-section .menu-content-item .left {
      width: 90%;
    } 

    .wine-section .menu-content-item .left .img {
      width: 20%;
    } 

    .wine-section .menu-content-item .left .img img{
      display: block;
      margin: 0 auto;
    } 

    .wine-section .menu-content-item .left details {
      width: 80%;
    } 

    .wine-section .menu-content-item .right {
      width: 10%;
      text-align: right;
    } 

  }
  
  @media (max-width: 650px) {
    
    .menu-header .hr {
        min-width: 30%;
    }
     
    .menu-content {
        padding-inline: 10px;
    }
    
    .menu-content-item {
      width: 100%;
    }

    .wine-section .menu-content {
      padding-block: 0px 50px;
    }


  } 