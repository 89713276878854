/* General Styles */
  
.reservation {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(./../../images/pattern.svg);
  }

  .reservation-card {
    width: 50vw;
    background-color: rgba(255, 255, 255, 0.659);
    border-radius: 25px;
    margin: 15px auto;
  }

  .qr-code-container {
    margin-block: 20px;
  }

  .reservation-banner {
    background-color: var(--faded-pink);
    padding: 20px;
    text-align: center;
    width: 100% !important;
  }
  
  .tagline {
    font-style: italic;
    color: #666;
  }

  
.reservation-working-hours {
    margin-block: 30px;
}

.reservation-working-hour-title {
    margin-block: 10px;
    font-size: 20px;
    line-height: 40px;
    text-transform: uppercase;
}

.reservation-working-hours .opening-hrs{
    display: flex;
    /* flex-direction: column; */
    gap: 30px;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;
}

.qr-code-container, .reservation-info-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reservation-info-content .reservation-info, .reservation-info-content .reservation-working-hours {
    margin-top: 20px;
    line-height: 35px;
    width: 75%;
}


@media (max-width: 1025px) {
    .reservation-card {
        width: 70vw;
    }

    .reservation-info-content .reservation-info, .reservation-info-content .reservation-working-hours {
        width: 90%;
    }
    
}


@media (max-width: 500px) {
    .reservation-card {
        width: 90vw;
    }

    .reservation-info-content .reservation-info, .reservation-info-content .reservation-working-hours {
        width: 95%;
    } 
}

@media (max-width: 325px) {
    .qr-code-container img{
        width: 90%;
    }
}
