 .error-page {
    height: 99vh;
    overflow: hidden;
    color: var(--grey);
    background-image: url(./../../images/pattern.svg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.error-wrapper .error-content {
    margin: 2%;
    /* border: 2px solid #df929c ; */
}

.error-wrapper .error-content .nav  {
    position: relative;
    left: 5px;
    top: 5px;
    display: flex;
    align-items: center;
}

.error-wrapper .error-container {
    display: flex;
    justify-content: space-evenly;
}

.error-wrapper .error-container .error-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.error-wrapper .error-container .error-content p.err-title{
    font-size: 65px;
}

.error-wrapper .error-container .error-content p.err-sub-title{
    font-size: 45px;
}

.error-wrapper .error-container .error-content p.err-text{
    font-size: 20px;
}

.error-wrapper .error-content .error-img-container{
    display: flex;
    justify-content: center;    
    flex-direction: column;
    align-items: center;
}


.error-wrapper .error-content .error-img-container img{
    width: 50vw;
}

@media (max-width: 426px) {
    .error-page {
        overflow: scroll;
    }

    .error-container {
        flex-direction: column-reverse;
    }

    .error-content {
        text-align: center;
    }

    .error-wrapper .error-container .error-content p.err-title{
        font-size: 35px;
    }
    
    .error-wrapper .error-container .error-content p.err-sub-title{
        font-size: 25px;
    }
    
    .error-wrapper .error-container .error-content p.err-text{
        font-size: 18px;
    }

}