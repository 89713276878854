/* Navbar */
.navbar {
  position: sticky;
  top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px ;
    background: rgb(250, 255, 255);
    box-shadow: 0 0.5px var(--faded-pink);
    background-image: url(./../../images/pattern.svg);
    z-index: 10000;
  }

  .navbar .img-container {
    display: none;
  }

  .navbar img {
    width: 90px;
  }
  
.navbar .nav-links {
    /* margin-right: 20px; */
    width: 90%;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links button {
    background: none;
    border: none;
    color: var(--faded-pink);
    font-size: 1rem;
    cursor: pointer;
    font-size: 20px;
    text-transform: uppercase;
  }

  .nav-links button.active{
    color: var(--hot-pink)
  }
  
  .hamburger {
    display: none;
    font-size: 3rem;
    background: none;
    border: none;
    cursor: pointer;
  }

  
  /* Popup */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  /* .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    min-width: 200px;
    max-width: 500px;
  } */

  .popup-content {
    position: absolute; /* Make popup position absolute */
    background: white;
    padding-block: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    min-width: 200px;
    /* min-height: 600px; */
    z-index: 1001; /* Higher than overlay */
  }
  
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .popup-content ul {
    list-style-type: none;
    text-align: left;
  }

  .popup-content ul li {
    display: block;
    padding: 20px;
    transition: background-color 0.3s;
    text-transform: capitalize;
  }

  .popup-content ul li:hover {
    background-color: #d63384;
    color: white;
    font-size: 18px;
    letter-spacing: 1px;
  }

  .navbar button:hover { 
    cursor: pointer;
    padding-inline: 5px;
    color: var(--hot-pink);;
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {

    .navbar .nav-links {
      width: 90%;
    }   
  }

  @media (max-width: 500px){
    .navbar {
      justify-content: space-between;
      padding-block: 0;
    }

    .nav-links {
      display: none;
      flex-direction: column;
      background: white;
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    .nav-links.active {
      display: none;
    }
  
    .hamburger {
      display: block;
      margin-bottom: 10px
    } 
    .homepage .navbar img {
        width: 100px;
    }

    .navbar .img-container {
      display: block;
    }
  }