.slider {
    position: relative;
    width: 90%;
    height: 400px;
    margin: auto;
    overflow: hidden;
  }
  
  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
  }
  

  