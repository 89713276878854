/* General Styles */
  
  .homepage {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(./../../images/pattern.svg);

  }
  
  /* Main Content */
  .logo-text {
    font-size: 3rem;
    color: #d63384;
    margin: 0;
  }

  .main-content .logo img {
    width: 40%;
  }
  
  .tagline {
    font-style: italic;
    color: #666;
  }

  .slogan span{
    font-style: italic;
    font-size: 25px;
    line-height: 37.5px;
    text-align: center;
    color: #868686;
  }

  .social-icons {
    margin-bottom: 10px;
  }
  
  .homepage .social-icons a {
    margin: 0 10px;
    color: #d63384;
    font-size: 1.2rem;
    text-decoration: none;
  }

  @media (max-width: 885px) {
    .main-content div.logo > img {
        width: 60%;
    }
  }

  @media (max-width: 500px) {
    .main-content div.logo > img {
        width: 300px;
    }
    .slogan span {
      display: block;
    }
  }
  