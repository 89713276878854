
:root {
    --grey: #868686;
    --pink: #DF929C;
    --hot-pink: #DA127C;
    --light-pink: rgb(250, 215, 225, 0.3);
    --white: #fff;
    --lime: rgba(223, 232, 205, 1);
    --faded-pink:  rgb(223, 146, 156);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 15px;
}

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}
