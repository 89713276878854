.banner {
    background-color: var(--lime);
    color: var(--white);
    width: 100%;
}

.banner .img-container {
    position: relative;
    left: 0;
}

.banner .img-container img{
    width:  80px;
}

.wrapper {
    width: 88%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.banner .info {
    margin: 0 auto;
    color: var(--hot-pink);
    font-weight: 600 !important;
    font-style: italic;
}

@media (max-width: 500px) {
    .banner .img-container {
        display: none;
    }
}