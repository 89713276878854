  .container, .heading{
    background-image: url(./../../images/pattern.svg);
  }
  
  .icon {
    margin-right: 10px;
    width: 15px;
  }
  
  .content  {
    /* border: 2px solid red; */
    width: 80%;
    margin: auto;
    background-color: var(--white);
  }
  
  
  .metadata {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .metadata > div {
    margin-top: 10px;
  }

  .is-open {
    margin-inline: 5px;
    font-size: 120%;
    color: #DF929C;
  }
  
  .is-open.open {
    color: green;
  }
  
  .is-open.closed {
    color: red;
  }
  
  .contact-info {
    display: flex;
  }
  
  .contact-info > div {
    padding-inline: 10px;
  }
  
  
  .container .menu-nav {
    /* border: 2px solid red; */
    display: flex;
    padding: 10px 20px;
    width: 100%;
  }

  .menu-nav.sticky {
    position: fixed;
    top: 70px;
    left: 10%;
    width: 80%; /* Ensure it spans the viewport width */
    background-color: white; /* Maintain visibility */
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for distinction */
  }
  
  .menu-nav fieldset {
    min-width:max-content;
    width: 25%;
    padding-left: 10px;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .menu-nav fieldset select  {
    outline: none;
    border: none;
    width: 95%;
    margin: auto;
    position: relative;
    top: -5px;
  }

  .menu-nav fieldset legend  {
    font-size: small;
  }
  
    .main {
      padding: 2%;
    }

  /* popup styling */
  .hours-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 400px;
    padding: 20px;
    text-align: center;
  }
  
  .hours-popup h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .hours-popup ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .hours-popup li {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #555;
    display: flex;
    justify-content: space-between;
  }
  
  .hours-popup li strong {
    color: #333;
  }
  
  .hours-popup button {
    background-color: #ff5a5f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .hours-popup button:hover {
    background-color: #e04646;
  }
  
  /* Backdrop styling */
  .hours-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }


  .scroll-to-top {
    position: fixed;
    bottom: 25%; /* 25% from the bottom of the viewport */
    right: 20px; /* Adjust this for horizontal placement */
    background-color: #DA127C; /* Bright, noticeable color */
    color: white;
    border: none;
    border-radius: 50%; /* Circular button */
    width: 50px;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
    z-index: 1000; /* Ensure it appears above other elements */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8; /* Slight transparency */
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .scroll-to-top:hover {
    opacity: 1; /* Fully visible on hover */
    transform: scale(1.1); /* Slightly larger on hover */
  }
  
  .menu-nav.sticky ~ .scroll-to-top {
    display: flex; /* Show the button only when menu-nav is sticky */
  }

    
  /* Responsive Design */
  @media (min-width: 2060px) {
    .content, .menu-nav.sticky  {
      width: 60%;
    }

    .menu-nav.sticky {
      left: 20%;
      right: 20%;
    }
  }
  @media (max-width: 1023px) {
    .content {
      width: 90%;
    }

    .menu-nav {
      flex-direction: column;
      justify-content: center;
    }

    .menu-nav > * {
      padding-block: 5px;
    }

    .menu-nav fieldset {
      width: 100%;
    }

    .menu-nav.sticky {
      left:  5%;
      right:  5%;
      top: 70px;
      width: 90%;
    }

    .menu-nav.sticky fieldset {
      display: none;
    }
  }

  /* @media (max-width: 800px) {
    .menu-nav.sticky {
      width: 100%;
    }
  } */
  
  @media (max-width: 480px) {
    .menu-header .hr {
      min-width: 20% !important;
    }

    .wine-section .menu-content-item .right {
      width: fit-content !important;
    }

    

    .content, .menu-nav.sticky{
      width: 100%;
    }

    .menu-nav.sticky {
      left: 0;
      padding: 0;
    }

    .scroll-to-top {
      bottom: 10%;
    }
    
  } 