.contact-wrapper, .heading {
    background-image: url(./../../images/pattern.svg);

  }
  

.background-wrapper {
    /* border: 2px solid green; */
    margin: 30px;
    border-radius: 35px;
    background-image: url(./../../images/contact-bg.svg) ;
    background-size: cover;
 }

 .mobile-navbar {
  display: none;
 }
  
  /* Contact Container */
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 50px auto;
    padding:100px 0;
    /* border: 2px solid rgb(43, 255, 0); */
    overflow: hidden;
  }
  
  .contact-form-section,
  .contact-map-section {
    flex: 1;
    min-width: 320px;
    padding: 20px;
  }

  .contact-form-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  
  /* Contact Form Section */

  .contact-form-section .form-heading {
    font-size: 54px;
    line-height: 24px;
  }

  .form-heading.poppins-bold {
    color: black;
  }

  .contact-form-section .form-heading span {
    color: var(--faded-pink);
  }
  
  .contact-form-section p {
    color: #666;
    line-height: 1.5;
    margin: 10px 0 20px;
  }
  
  /* Contact Form */
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contact-form input,
  .contact-form select {
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form button {
    padding: 10px;
    font-size: 1rem;
    color: #fff;
    background-color: #d63384;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #b02a70;
  }
  
  /* Map Section */
  .contact-map-section iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
  }

  .contact-details {
    margin-block: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-details .contact {
    display: flex;
    align-items: center;
    margin-block: 10px;
    gap: 10px;
  }



  .contact-details .contact .details {
    /* width: 60%; */
    margin-left: 10px;

  } 

  .contact-details .contact .details > p {
    margin-block: 2px;
    font-size: 16px;
    line-height: 20px;
  }

  .contact-details .contact .details > p.heading {
    text-transform: uppercase;
    color: black;
  }

  .contact-details .contact .details > p.info {
    color: var(--faded-pink);
  }

  .contact-form-section .social-icons p {
    margin-block: 10px;
  }

  .contact-form-section .social-icons img {
    width: 35px;
    margin-right: 10px
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .background-wrapper {
        background-size: contain;
    }

    .contact-container {
        height: 70%;
    }

    .contact-form-section {
        background-color: white;
    }

    
  }
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
    };

    .contact-map-section {
        min-height: 400px;
    }

    .contact-container .contact-form  .contact-details {
        display: flex;
        justify-content: space-between;
    }

    .footer-group .left {
        width: 55%;
    }
  }

  @media (max-width: 426px) {
    .background-wrapper {
      background-image: none;
      margin: 0;
    }

    .contact-container {
      width: 100%;
      margin: 10px auto;
      padding-block: 0;
    }

    .contact-form-section .form-heading {
      font-size: 45px;
      line-height: 20px;
    }

    .banner, .header {
      display: none;
    }

    .mobile-navbar {
      display: flex;
      justify-content: space-between;
      flex-direction:  row-reverse;
      margin: 20px;
    }
  }

  @media (max-width: 426px) {
    .contact-form-section .form-heading {
      font-size: 40px;
    }
  }